import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DoarFelicidadeImage from '../../assets/images/doar-felicidade-completo.png';
import FimProgramaImage from '../../assets/images/fim-programa.jpeg';
import { Footer } from '../../components/Footer';
import { KidCard } from '../../components/KidCard';
import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { KidInterface } from '../../interfaces/kids';
import { api } from '../../services/api';

export const Home: React.FC = () => {
  const [kids, setKids] = useState<KidInterface[]>([]);
  const [loading, setLoading] = useState(true);

  useDocumentTitle('Natal Prio - Home');

  const fetchData = async () => {
    setLoading(true);

    const response = await api.get('/getAllKids', {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    setKids(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CAMPAIGN_OVER = true;

  if (CAMPAIGN_OVER) {
    return (
      <>
        <AppBar position="relative">
          <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              alignItems="center"
            >
              <img
                width="100%"
                src={DoarFelicidadeImage}
                alt="DoarFelicidadeImage"
              />
            </Box>
          </Toolbar>
        </AppBar>
        <img src={FimProgramaImage} alt="Fim da campanha" width="100%" />
        <Footer />
      </>
    );
  }

  return (
    <>
      <AppBar position="relative">
        <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            alignItems="center"
          >
            <a href="#childrenList">
              <img
                width="100%"
                src={DoarFelicidadeImage}
                alt="DoarFelicidadeImage"
              />
            </a>
          </Box>
        </Toolbar>
      </AppBar>
      <Layout>
        <Box
          id="childrenList"
          display="flex"
          flexDirection="column"
          flex={1}
          alignItems="center"
          marginBottom={4}
        >
          <Typography
            fontWeight={700}
            fontFamily="Poppins"
            marginLeft={-6}
            marginRight={-8}
            fontSize="30px"
            color="#FB8E03"
          >
            PARTICIPE DA NOSSA <br /> CAMPANHA DE NATAL
          </Typography>
          <Typography
            fontWeight={400}
            fontFamily="Poppins"
            marginLeft={-4}
            marginRight={-4}
            variant="h6"
            color="#FB8E03"
            paddingTop={2}
            paddingBottom={4}
          >
            Escolha uma criança ou jovem para doar um presente e fazê-la feliz.
          </Typography>
        </Box>

        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={4}>
            {kids
              .sort((a, b) => {
                const aIsAdopted = !!a.nome_doador;
                const bIsAdopted = !!b.nome_doador;

                return (
                  +aIsAdopted - +bIsAdopted || a.nome.localeCompare(b.nome)
                );
              })
              .map((kid, index) => (
                <KidCard key={kid.nome} kid={kid} index={index} />
              ))}
          </Grid>
        )}
      </Layout>
      <Footer />
    </>
  );
};
